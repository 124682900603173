import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, NgZone, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Helper } from 'src/helper/helper';
import { ExternalFeedbackModalService } from './external-feedback-modal.service';
import { AppComponent as father } from '../app.component'
import { I } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { FullComponent } from '../layouts/full/full.component';
@Component({
  selector: 'app-external-feedback-modal',
  templateUrl: './external-feedback-modal.component.html',
  styleUrls: ['./external-feedback-modal.component.scss']
})

export class ExternalFeedbackModalComponent implements OnInit {
  action: string;
  local_data: any;
  disabled: boolean = false;
  loading: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom
  token: any;
  currentUser : any;
  timer : any;
  counterStart = 10;
  mailObj = {firstname:'',lastname:'',email:'',company_name:'',description_email:''}
  public form: FormGroup = Object.create(null);

  constructor(public fullComponent: FullComponent, private fb: FormBuilder, private http: HttpClient, private translate: TranslateService, private _service: ExternalFeedbackModalService, private _helper: Helper, private ngZone: NgZone, private router: Router, public datePipe: DatePipe, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<ExternalFeedbackModalComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    //console.log(this.local_data);

    dialogRef.disableClose = true;
    this.action = this.local_data.action;
    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

    this.form = this.fb.group({
      firstname: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      lastname: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ],
      company_name: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      description_email: [null, Validators.compose([ Validators.maxLength(699)])],
    });

    setTimeout(() => {
      let modal = document.getElementsByClassName('custom-modal')[0];
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modal.setAttribute('style', 'opacity:1');
      modalBase.setAttribute('style', 'display:block');
    }, 400);
    }

  ngOnInit(): void {

    let modal = document.getElementsByClassName('cdk-overlay-backdrop');
    let modalOverlay = document.getElementsByClassName('cdk-global-overlay-wrapper');
;
    for (let index = 1; index < modal.length; index++) {
      modal[index].remove();
      modalOverlay[index].remove();
    }

  }
  sendRequest(){
    //console.log('request');
    this._service.addExternalRequest(this.mailObj).then(res=>{
      if(res.success){
        this._snackBar.open(res.message, '', {
          duration: 5000,
          panelClass: ['success-snackbar'],
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.closeDialog('Success')
      }
    }).catch(error => {
      if (error.status == 401) {
        this.fullComponent.refreshToken(1);

      } else {
        this.manageError();
      }


    });
  }

  ngOnDestroy(): void {


  }

  manageError() {
    if (this.currentUser.language == "it") {
      var errorMessage = this.translate.translations.it.ErrorServer;
    } else {
      var errorMessage = this.translate.translations.en.ErrorServer;
    }

    this._snackBar.open(errorMessage, '', {
      duration: 5000,
      panelClass: ['danger-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,

    });
  }

  closeDialog(event:any){
    let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
    modalBase.setAttribute('style', 'display:none');
    this.dialogRef.close({ event: event });

  }






}
