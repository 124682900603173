import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, NgZone, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Helper } from 'src/helper/helper';
import { SessionExpiredModalService } from './session-expired-modal.service';
import { AppComponent as father } from '../app.component'
import { I } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-session-expired-modal',
  templateUrl: './session-expired-modal.component.html',
  styleUrls: ['./session-expired-modal.component.scss']
})

export class SessionExpiredModalComponent implements OnInit {
  action: string;
  local_data: any;
  disabled: boolean = false;
  loading: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom
  token: any;
  currentUser : any;
  timer : any;
  counterStart = 10;
  constructor( private http: HttpClient, private translate: TranslateService, private sessionExpiredModalService: SessionExpiredModalService, private _helper: Helper, private ngZone: NgZone, private router: Router, public datePipe: DatePipe, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<SessionExpiredModalComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    //console.log(this.local_data);

    dialogRef.disableClose = true;
    this.action = this.local_data.action;
    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

    setTimeout(() => {
      let modal = document.getElementsByClassName('custom-modal')[0];
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modal.setAttribute('style', 'opacity:1');
      modalBase.setAttribute('style', 'display:block');
    }, 400);
    }

  ngOnInit(): void {
    father.modalSessionEnd = 1;
    let modal = document.getElementsByClassName('cdk-overlay-backdrop');
    let modalOverlay = document.getElementsByClassName('cdk-global-overlay-wrapper');
    //console.log(father.modalSessionEnd);
    for (let index = 1; index < modal.length; index++) {
      modal[index].remove();
      modalOverlay[index].remove();
    }
    let that = this;
    that.timer = setInterval(function () {
      if (that.counterStart <= 0) {
        // //console.log('cleare')
        clearInterval(that.timer);
      }
      if (that.counterStart > 0){
        // //console.log('time -')
        that.counterStart--;
      }else{
        // //console.log('redirect')
       clearInterval(that.timer);
       that.dialogRef.close({ event: 'Cancel' });
       that.router.navigate(['/']);
      }
    }, 1000);
  }
  ngOnDestroy(): void {
    father.modalSessionEnd = 0;
    //console.log(father.modalSessionEnd);

  }

  closeDialog(){
    let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
    modalBase.setAttribute('style', 'display:none');
    this.dialogRef.close({ event: 'Cancel' });
    father.modalSessionEnd = 0;
    this.router.navigate(['/']);
  }

  extendSession(){
    let that = this;
    clearInterval(that.timer);
    this.sessionExpiredModalService.getRefreshToken(this.token.access_token, 1).then(res => {
      sessionStorage.removeItem('loginUserToken');
      sessionStorage.setItem('loginUserToken', JSON.stringify(res.data.token));
      sessionStorage.removeItem('loginUser');
      sessionStorage.setItem('loginUser', JSON.stringify(res.data.user));
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modalBase.setAttribute('style', 'display:none');
      father.modalSessionEnd = 0;
      this.dialogRef.close({ event: 'ExtendSession' });

    }).catch(error => {

      // let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      // modalBase.setAttribute('style', 'display:none');
      // this.dialogRef.close({ event: 'Cancel' });
      // this.router.navigate(['/']);


    });
  }




}
