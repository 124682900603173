import { AfterViewChecked, Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, NgZone, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Helper } from 'src/helper/helper';
import { FullComponent } from '../layouts/full/full.component';
@Component({
  selector: 'app-price-block-modal',
  templateUrl: './price-block-modal.component.html',
  styleUrls: ['./price-block-modal.component.scss']
})

export class PriceBlockModalComponent implements OnInit {
  action: string;
  local_data: any;
  disabled: boolean = false;
  loading: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom
  token: any;
  currentUser : any;
  assumedUser : any;
  versionProduct : any;
  constructor( private fullComponent : FullComponent ,private http: HttpClient, private translate: TranslateService, private _helper: Helper, private ngZone: NgZone, private router: Router, public datePipe: DatePipe, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<PriceBlockModalComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    //console.log(this.local_data);

    dialogRef.disableClose = true;
    this.action = this.local_data.action;
    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    setTimeout(() => {
      let modal = document.getElementsByClassName('custom-modal')[0];
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modal.setAttribute('style', 'opacity:1');
      modalBase.setAttribute('style', 'display:block');
    }, 1);

    }

  ngOnInit(): void {
  }

  // ngAfterViewInit(): void {
  //   let modal = document.getElementsByClassName('custom-modal')[0];
  //   let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
  //   modal.setAttribute('style', 'opacity:1');
  //   modalBase.setAttribute('style', 'display:block');

  // }

  closeDialog(){

    this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);

    var objSegment = {};

    objSegment = {
      action : ((this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? '[STAFF] Chiusura modale per rifiuto acquisto funzionalità bloccate' : this.assumedUser == 1 ? '[STAFF] Chiusura modale per rifiuto acquisto funzionalità bloccate' : 'Chiusura modale per rifiuto acquisto funzionalità bloccate'),
      source : this.local_data.sourcePage
    };

    if(this.fullComponent.activeSegmentDev == true ){

      this.fullComponent.trackSegment(objSegment);
    }


    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);


    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
    if(this.versionProduct == 2){
      /* IA - SEGMENT */
      if (window.location.host == 'marketplace.timeflow.it') {
        this.fullComponent.trackSegment(objSegment);
      }
    } else {

      if(window.location.host == 'localhost:4200'){

      } else {

        if (window.location.host == "procurement.timeflow.it") {
          this.fullComponent.trackSegment(objSegment);


        } else if (window.location.host == "whitelabel.daytrend.it" ) {

        } else if (window.location.host == "procurement.daytrend.it" ) {

        } else if (window.location.host == "dev.mkt.daytrend.it" ) {

        } else {
          if(!(window.location.host.includes('daytrend'))){

            this.fullComponent.trackSegment(objSegment);

          }
        }
      }
    }

    let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
    modalBase.setAttribute('style', 'display:none');
    this.dialogRef.close({ event: 'Cancel' });
  }
  doAction(){
    this.dialogRef.close({ event: 'Cancel' });
    if (this.currentUser.role == "Supplier" || this.currentUser.role == "Supplier Management") {
      if(this.local_data.openCheckout == true){
        this.dialogRef.close({ event: 'openCheckout' });
      } else {
        this.router.navigate(['/my_plans_supplier'])
      }
    } else {
      this.router.navigate(['/my_plans'])
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('My Plans'));
    }
  }

  goToCreateNeed(){

    if(this.local_data.state_function == 'VP'){
      this.dialogRef.close({ event: 'goToCreateFixedPrice' });
    } else {
      this.dialogRef.close({ event: 'goToCreateTimeMaterial' });
    }

  }

}
