import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';

@Injectable({
  providedIn: 'root'
})
export class CompleteProfileModalGlobalServices {
  header: any;
  constructor(private _http: HttpClient, private _helper: Helper) {
  }

  getRefreshToken(token : any, extend_session : any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/refresh', {token : token, extend_session: extend_session}, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  setHeader() {
    this.header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this._helper.getUserConfigData().access_token}`)
    };
  }
  addCountSkip(value: any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'company/addCountSkip', { counter: value }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
