import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';



@Injectable({
  providedIn: 'root'
})
export class ShareModalCvProjectService {
  header: any;
  constructor(private _http: HttpClient, private _helper: Helper) { }


  getSendInfo(obj:any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'shareInformations/getSendInformation', { ...obj }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addSendInfo(obj: any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'shareInformations/addSendInformation', { ...obj }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  resendSendInfo(obj: any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'shareInformations/resendSendInformation', { ...obj }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  setHeader() {
    this.header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this._helper.getUserConfigData().access_token}`)
    };
  }

}
