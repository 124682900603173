import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
@Injectable({
  providedIn: 'root'
})
export class OpenService {
header:any;
  constructor(private _http: HttpClient, private _helper: Helper) { }
  private token!: string | null;

  set currentToken(token) {
    this.token = token;
  }

  get currentToken(): string | null {
    return this.token;
  }


  addToken(search_services: any, type_version: any, type_caller:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/addTokenOpenAccess', { redirect_url: search_services, type_version:type_version ,type_caller:type_caller})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  projectFixedDoc(doc: any): Promise<any> {

    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'client/need/projectFixedPrice/projectFixedDoc', doc, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  sendMailTwice(emailUser: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/sendConfirmEmailApi', { email: emailUser })
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  getCodificValues(token:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/encodedList?token=' + token, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    })
  }

  getMacroList(token:any,type: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/serviceList?type=' + type +'&token='+token)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  checkEmail(email: any, language: any, role: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/checkEmail', { email: email, language: language, role: role })
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }
  addNeedAndNeedResource(need: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'client/need/createNeedAndNeedResource', { ...need }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  addProjectFixedPrice(proj: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'client/need/projectFixedPrice/create', { ...proj }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  projectDoc(doc: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'client/need/projectDoc', doc, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getParamLogin(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/getParamLogin?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  saveContact(obj: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/saveContact', obj)
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  createuser(register: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/register', { ...register })
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  saveProjectOpen(obj: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/saveProjectOpen', obj)
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  checkPiva(vat_number: any, role: any, foreign_company: any, language: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/checkPiva', { vat_number: vat_number, role: role, foreign_company: foreign_company, language: language })
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  checkTokenRegistration(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/checkTokenRegistration?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }
  searchByFilters(token:any,filters: any): Promise<any> {
    filters.token = token
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'services/searchServices', filters)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getVersionProduct(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/getVersionProduct')
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  checkTokenAccess(token:any){
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/checkTokenAccess?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  searchServiceFromBar(token:any,name_service: any) {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/searchServiceFromBar', {token:token , name_service: name_service })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getJobSectors(token:any) {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/getSectorsOpen?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  positionQuotation(token: any, startDate:any,endDate:any,dayRate:any,nProfile:any ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/positionQuotationOpen', {token: token, dateStart : startDate,dateEnd : endDate,rate : dayRate,profileNumber : nProfile,})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getTechnologies(token:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/getTechOpen?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getLanguages(token:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/getLanguagesOpen?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  filterProfile(obj:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/filterProfileOpen', { url: obj.url, start_profile: obj.start_profile, limit_page: obj.limit_page, page_id: obj.page_id, order_by: obj.order_by, asc :obj.asc, type: obj.type, search_word: obj.search_word, premium_profile: obj.premium_profile, type_profiles: obj.type_profiles, type_competence: obj.type_competence,
          technology: obj.technology, type_languages: obj.type_languages, languages: obj.languages, seniority:obj.seniority, only_loco: obj.only_loco, localities: obj.localities, rate_min: obj.rate_min, rate_max: obj.rate_max, full_time: obj.full_time, now_available: obj.now_available, only_cv_upload: obj.only_cv_upload,
          only_profile_feedback: obj.only_profile_feedback, counter: obj.counter, token: obj.token })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  completeDetail(id_company: any, id_token_usage: any, profile_id: any, nomination_id :any, id_client_need:any,operations:any,isMatch:any, visualizzatioID : any, redirecSearch : any, company_id_external : any, token:any): Promise<any> {
    console.log(token)
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/getDetailCollaborationOpen', { token: token, company_id: +id_company, id_token_usage: id_token_usage, employee_id: +profile_id, nomination_id: nomination_id, id_client_need: id_client_need, operations: +operations, isMatch: isMatch, visualizzatioID: visualizzatioID, redirecSearch: redirecSearch, company_id_external: +company_id_external })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getRates(token:any, type: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/getRatesOpen?token=' + token + '&type=' + type)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  requestAvailableProfile(token : any, email: any, profile_id : any, language : any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/requestAvailableProfile',{ token: token, email: email, profile_id: profile_id, language : language }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    })

  }


}
