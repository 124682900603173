import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class OpenComponentLayoutServices {

  header: any;
  onDataChanged: Subject<any>;
  constructor(private _http: HttpClient, private _helper: Helper) {
    this.onDataChanged = new Subject();
  }


  addToken(search_services: any, type_version: any, type_caller: any,utm_source:any, utm_medium:any, utm_campaign:any, have_sidebar:any, language : any, company_id : any, employee_id : any, mode_operation : any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/addTokenOpenAccess', { redirect_url: search_services, type_version: type_version, type_caller: type_caller, utm_source: utm_source, utm_medium: utm_medium, utm_campaign: utm_campaign, have_sidebar: have_sidebar, language : language, company_id : company_id, employee_id : employee_id, mode_operation:mode_operation })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }

  getCodificValues(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'open/encodedList?token=' + token, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    })

  }

  getSidebar(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'sidebar/getSidebarElement?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
