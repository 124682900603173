<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="my-shade-custom" *ngIf="isLoadingResults">
  <div class="spinner-container">
    <div class="loadGifCustom"></div>
    <img class="spin-image" [src]="logoSpin" alt="spinner" width="100px">
  </div>
</div>
<mat-nav-list appAccordion>
  <div class="navbar-container-custom">
    <div class="xclose" (click)="closeSidebar()" *ngIf="responsiveWidth <= 1024">
      <img src="assets/images/new-card-icons/X-white.svg" alt="">
    </div>
    <div class="logo-navbar">
      <div class="logo-part" [style.background-image]="'url(' + logo + ')'">
        <!-- <img alt="homepage" src="{{logo}}"> -->
      </div>
    </div>

    <div class="navbar-elements-container" appAccordionLink *ngFor="let menuitem of user_menus"
      routerLinkActive="selected" group="{{menuitem.state}}" (click)="scrollToTop()">

      <div class="navbar-element">
        <!-- <img class="triangle-custom" src="assets/images/sidebar-icons/Union-full.svg" alt="sidebar" [ngClass]="{'show' : activeMenu == menuitem.name}"> -->

        <a class="menu-item-custom" appAccordionToggle data-test="cypress-sidebar-element"
          [ngStyle]="{'margin-bottom' : ((menuitem.state == 'position_list' && subMenuOpen) || ( menuitem.state == 'company_list' || menuitem.state == 'services_list')) ? ' 0px' : '20px'  }"
          [routerLink]="menuitem.state != 'position_list' && ( menuitem.state != 'company_list' && menuitem.state != 'services_list') ? ['open/', menuitem.state, token] : []"
          (click)="setBg(menuitem.name,false)" (click)="menuitem.state == 'position_list' ? openSubMenu() :
              closeSubMenu()" (click)="refreshForNeed(menuitem.state)"
          [ngClass]="{'active' : activeMenu == menuitem.name}" *ngIf="menuitem.type === 'link'">
          <mat-icon class="custom-icon-sidebar" style="width: 24px;height: 24px;"
            svgIcon="{{menuitem.icon}}"></mat-icon>
          <span class="sidebar-elements"
            [ngClass]="navbarStretch ? 'sidebar-elements hide-it' : 'sidebar-elements show-it'">{{ menuitem.name |
            translate }}</span>
          <!-- <span fxFlex></span> -->
          <span class="chip-supplier"
            *ngIf="menuitem.state === 'collaborations_request' && (counterListRequest != 0 && counterListRequest)">
            <span class="label label-warning" style="border-radius: 20px;margin-left: 8px;"
              selected>{{counterListRequest}}</span>
          </span>
          <span class="chip-client"
            *ngIf="menuitem.state === 'collaborations_invite' && (counterListInvite != 0 && counterListInvite)">
            <span class="label label-warning" style="border-radius: 20px;margin-left: 8px;"
              selected>{{counterListInvite}}</span>
          </span>
          <span class="chip-client" *ngIf="menuitem.state === 'position_list'" style="margin-left: 12px;">
            <img src="assets/images/new-card-icons/chevron-down-white.svg" width="16px" height="16px" alt="">
          </span>
          <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
      </div>
      <div class="sub-menu closed" *ngIf="menuitem.state === 'position_list'">
        <div class="time-material" [routerLink]="menuitem.state == 'position_list' ? ['/', menuitem.state] : []">
          <div class="spot-circle"></div> Time & Material
        </div>
        <div class="fixed-price" [routerLink]="menuitem.state == 'position_list' ? ['/position_list_fixed_price'] : []">
          <div class="spot-circle"></div> Fixed Price
        </div>
      </div>

      <div class="sub-menu" *ngIf="menuitem.state === 'company_list'">
        <div class="time-material" (click)="setBg(menuitem.name,false);isInStep(0,menuitem.name)"
          [ngClass]="{'active' : returned == 'company_list' && activeMenu == 'Company' }"
          [routerLink]="menuitem.state == 'company_list' ? ['/', menuitem.state] : []">
          <div class="spot-circle"></div> {{'CompleteList' | translate}}
        </div>
        <div class="fixed-price" (click)="setBg(menuitem.name,false);isInStep(1,menuitem.name)"
          [ngClass]="{'active' : returned == 'company_review' && activeMenu == 'Company'}"
          [routerLink]="menuitem.state == 'company_list' ? ['/company_review'] : []">
          <div class="spot-circle"></div> {{'ToReview' | translate}} <span
            [ngClass]="companyCounter != 0 ? 'label label-warning' : ''"
            style="border-radius: 20px;margin-left: 8px;">{{companyCounter != 0 ? companyCounter : ''}}</span>
        </div>
      </div>

      <div class="sub-menu" *ngIf="menuitem.state === 'services_list'">
        <div class="time-material" (click)="setBg(menuitem.name,false);isInStep(2,menuitem.name)"
          [ngClass]="{'active' : returned == 'services_list' && activeMenu == 'Company Services'}"
          [routerLink]="menuitem.state == 'services_list' ? ['/', menuitem.state] : []">
          <div class="spot-circle"></div>{{'CompleteList' | translate}}
        </div>
        <div class="fixed-price" (click)="setBg(menuitem.name,false);isInStep(3,menuitem.name) "
          [ngClass]="{'active' : returned == 'services_review' && activeMenu == 'Company Services'}"
          [routerLink]="menuitem.state == 'services_list' ? ['/services_review'] : []">
          <div class="spot-circle"></div> {{'ToReview' | translate}} <span
            [ngClass]="servicesCounter != 0 ? 'label label-warning' : ''"
            style="border-radius: 20px;margin-left: 8px;">{{servicesCounter != 0 ? servicesCounter : ''}}</span>
        </div>
      </div>
    </div>




  </div>

</mat-nav-list>
