import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class BreadcrumbOpenService {
  header: any;
  onDataChanged: Subject<any>;
  constructor(private _http: HttpClient, private _helper: Helper) {
    this.onDataChanged = new Subject();
  }






}
