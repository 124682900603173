import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class VerticalHeaderOpenService {
  header: any;
  onDataChanged: Subject<any>;
  constructor(private _http: HttpClient, private _helper: Helper) {
    this.onDataChanged = new Subject();
  }

  private languageSubject = new BehaviorSubject<string>(localStorage.getItem('language') || 'it');;
  lang$ = this.languageSubject.asObservable();

  changeLanguageOpen(token: any, lang: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'open/changeLanguage', { token: token, language: lang})
        .subscribe((response: any) => {
          this.languageSubject.next(lang);
          resolve(response);
        }, reject);
    });
  }

  getParamLogin(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/getParamLogin?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

}
